import { Link } from "gatsby";
import * as React from "react";
import styled from "styled-components";
import { SeoComponent } from "../components/atoms";
import { HeaderComponent, FooterComponent } from "../components/organisms";

const NotFound = styled.div`
  align-items: center;
  background: linear-gradient(135deg, #162363 14%, #45c5c5 100%);
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 2rem;
  min-height: 240px;

  h2 {
    font-size: 3.6rem;
    margin: 0;
    padding: 0;
    text-align: center;
  }

  h3 {
    font-size: 2rem;
    margin: 0;
    padding: 0;
  }

  p {
    font-size: smaller;
    text-align: center;
  }

  a {
    font-size: small;
    padding: 4px 12px;
  }
`;

const NotFoundPage = () => {
  return (
    <>
      <HeaderComponent />
      <NotFound>
        <h2>404</h2>
        <h3>Not Found</h3>
        <p>申し訳ありません、ページが見つかりません</p>
        <Link to="/">トップページへ</Link>
      </NotFound>
      <FooterComponent />
    </>
  );
};

export default NotFoundPage;
export const Head = () => (
  <SeoComponent title="ページが見つかりません" pathName="/404" />
);
